import { atomWithStorage } from 'jotai/utils';
import { ViewingHistoryItem } from '@/lib/types/api/viewing-history';
import createStorage from "@/lib/helpers/createStorage";
import { canAccessStorage } from '@/lib/helpers/is-storage-available';

const storage = createStorage<ViewingHistoryItem[] | null>('localStorage')

let storedHistory = null

if (canAccessStorage('localStorage')) {
  storedHistory = JSON.parse(window.localStorage.getItem('viewing_history')!)
}

export const percentageWatchedViewingHistoryAtom = atomWithStorage<ViewingHistoryItem[] | null>('percentageWatchedViewingHistory', storedHistory, storage);
