import safeFetch from '@/lib/helpers/safe-fetch'

export async function addFavoriteVideo(uid: string, video_cid: string) {
  return await put(`/api/profile/my-list-videos/${uid}/?video_cid=${video_cid}`);
}

export async function removeFavoriteVideo(uid: string, video_cid: string) {
  return await httpDelete(`/api/profile/my-list-videos/${uid}/?video_cid=${video_cid}`);
}

export async function addFavoriteShow(uid: string, show_cid: string) {
  return await put(`/api/profile/my-list-shows/${uid}/?show_cid=${show_cid}`);
}

export async function removeFavoriteShow(uid: string, show_cid: string) {
  return await httpDelete(`/api/profile/my-list-shows/${uid}/?show_cid=${show_cid}`);
}

export async function removeWatchedVideo(uid: string, video_cid: string) {
  return await httpDelete(`/api/profile/viewing-history/${uid}/?video_cid=${video_cid}`);
}

async function put(url: string) {
  const response = await safeFetch(url, { method: 'PUT', credentials: 'include'})
  return await response.json()
}

async function httpDelete(url: string) {
  const response = await safeFetch(url, { method: 'DELETE', credentials: 'include'})
  return await response.json()
}

